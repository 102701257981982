@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap");

/* VARIABLES */
:root {
  --main-bg-color-dark: #212529;
  --main-text-color-dark: #e8e8e8;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background-color: #1d1d1d;
  font-family: "Montserrat", sans-serif;
  background-color: var(--main-bg-color-dark);
  color: var(--main-text-color-dark);
}
.page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.heading {
  font-size: 45px;
}
.highlight {
  color: #7f78d2;
}
.countdown-wrapper {
  max-width: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 40px 0;
}
.countdown-box {
  background-color: #413c69;
  font-size: 50px;
  font-weight: 700;
  color: #dcd6f7;
  border-radius: 15px;
  width: 160px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.legend {
  font-size: 30px;
  color: #a6b1e1;
}
.wish-message {
  font-size: 25px;
  font-weight: 600;
  color: #7f78d2;
}
.birthdate {
  font-size: 25px;
  font-weight: 600;
  color: #7f78d2;
}
.App {
  text-align: center;
  font-size: 300px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
